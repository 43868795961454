/**
 * Shopping Cart
 */
const shoppingCart = (() => {
  //  Private methods and properties
  let cart = [];

  function Item(name, price, count) {
    this.name = name;
    this.price = price;
    this.count = count;
  }

  function saveCart() {
    localStorage.setItem("shoppingCart", JSON.stringify(cart));
  }

  function loadCart() {
    JSON.parse(localStorage.getItem("shoppingCart"));
  }

  loadCart();

  //  Public methods and properties

  const obj = {};

  obj.addItemToCart = (name, price, count) => {
    for (const i in cart) {
      if (cart[i].name === name) {
        cart[i].count += count;
        saveCart();
        return;
      }
    }
    const item = new Item(name, price, count);
    cart.push(item);
    saveCart();
  };

  obj.setCountForItem = (name, count) => {
    for (const i in cart) {
      if (cart[i].name === name) {
        cart[i].count = count;
        break;
      }
    }
    saveCart();
  };

  obj.removeItemFromCart = name => {
    for (const i in cart) {
      if (cart[i].name === name) {
        cart[i].count--;
        if (cart[i].count === 0) {
          cart.splice(i, 1);
        }
        break;
      }
    }
    saveCart();
  };

  obj.removeItemFromCartAll = name => {
    for (const i in cart) {
      if (cart[i].name === name) {
        cart.splice(i, 1);
        break;
      }
    }
    saveCart();
  };

  obj.clearCart = () => {
    cart = [];
    saveCart();
  };

  obj.countCart = () => {
    let totalCount = 0;
    for (const i in cart) {
      totalCount += cart[i].count;
    }

    return totalCount;
  };

  obj.totalCart = () => {
    let totalPrice = 0;
    for (const i in cart) {
      totalPrice += cart[i].price * cart[i].count;
    }

    return totalPrice.toFixed(0);
  };

  obj.listCart = () => {
    const cartCopy = [];
    for (const i in cart) {
      const item = cart[i];
      const itemCopy = {};
      for (const p in item) {
        itemCopy[p] = item[p];
      }
      itemCopy.total = (item.price * item.count).toFixed(0);
      cartCopy.push(itemCopy);
    }
    return cartCopy;
  };

  obj.displayCart = () => {
    let output = "";
    const cartArray = shoppingCart.listCart();
    for (const i in cartArray) {
      output += `<li class="sc-cart-item item-${i}"><span class="sc-cart-item__name">${
        cartArray[i].name
      }</span><button class="substract-item" data-name="${
        cartArray[i].name
      }">-</button><span class="item-count-num">${
        cartArray[i].count
      }</span><button class="add-item" data-name="${
        cartArray[i].name
      }">+</button><button class="sc-cart-delete delete-item" data-name="${
        cartArray[i].name
      }"><i class="icon-close-b-n"></i></button></li>`;
    }

    $("#show-cart").html(output);

    if (!cartArray.length) {
      $("#total-cart").html(
        `<span class="sc-cart-total__nothing">Ничего не выбрано</span>`
      );
      $("#total-cart-submit").html(``);
    } else {
      $("#total-cart").html(
        `<span class="sc-cart-total__name">Итого:</span>     
<span class="sc-cart-total__count total-count">${shoppingCart.countCart()}</span><span class="sc-cart-total__price total-price"></span> `
      );
      $("#total-cart-submit").html(
        `<a id="submit-form" data-fancybox="" data-src="#orderForm" href="#" class="button button-submit">Продолжить</a>`
      );
      $("#total-cart-text").html(
        `<p class="total-cart-text">Информацию о цвете корпуса (белый или черный), цветовой температуре (3000K или 4000K), прочие комментарии и вопросы по другому осветительному оборудованию можно указать на следующей странице в поле “Комментарии“. Нажмите “Продолжить”</p>`
      );
    }

    $(".first-item-count").val(
      $(".item-0")
        .find(".item-count-num")
        .text()
    );
    $(".second-item-count").val(
      $(".item-1")
        .find(".item-count-num")
        .text()
    );
  };

  //------------------
  return obj;
})();

export { shoppingCart };
