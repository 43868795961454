import { shoppingCart } from "./module";

(function() {
  "use strict";

  //    init
  videoPoster();
  thankUrl();

  if (window.location.hash === "#success-data") {
    $.fancybox.open({
      src: "#success-message",
      type: "inline",
      opts: {
        afterShow(instance, current) {
          window.location.hash = "";
        }
      }
    });
  }

  function thankUrl() {
    const location = window.location.origin;
    const thankUrl = `${location}/#success-data`;
    $(".thank-url-link").val(thankUrl);
  }
  // console.log($('.thank-url-link').val());

  function videoPoster() {
    const $playBtn = $(".video__button");
    const $poster = $(".video__poster");
    const $wrapper = $(".video__holder");
    const $iframe = $wrapper.find(".js-videoIframe");
    const src = $iframe.data("src");

    function playVideo() {
      $poster.add(".video__button").fadeOut();
      $iframe.attr("src", src);
    }

    $playBtn.on("click", playVideo);
  }

  $(".section-projects .items__container").slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1
        }
      }
    ]
  });

  function toggleMobileMenu() {
    $(".mobile-menu__btn").toggleClass("open");
    $(".mobile-menu").toggleClass("mobile-menu_open");
    $("html, body").toggleClass("overflowHidden");
  }

  // Mobile Menu
  $(".mobile-menu__btn").on("click", toggleMobileMenu);
  $(".mobile-menu__wrap a").on("click", toggleMobileMenu);

  $(".phone-mask").mask("+7 (999) 999 99 99");

  //  Smooth Scroll To Anchor
  $(".smooth").on("click", function(event) {
    event.preventDefault();
    $(".smooth").removeClass("active");
    $(this).addClass("active");

    //забираем идентификатор бока с атрибута href
    const id = $(this).attr("href");

    const //узнаем высоту от начала страницы до блока на который ссылается якорь
    top = $(id).offset().top;

    //анимируем переход на расстояние - top за 600 мс

    if (window.matchMedia("(max-width: 768px)").matches) {
      $("body,html").animate({ scrollTop: top - 60 }, 1000);
    } else {
      $("body,html").animate({ scrollTop: top - 40 }, 1000);
    }
  });

  $.validate({
    form: "#registratioForm, #acquireForm, #orderForm"
  });

  //Shopping Cart
  $("#show-cart").on("click", ".delete-item", function(e) {
    e.preventDefault();
    const name = $(this).data("name");
    shoppingCart.removeItemFromCartAll(name);
    shoppingCart.displayCart();
  });

  $("#show-cart").on("click", ".substract-item", function(e) {
    e.preventDefault();
    const name = $(this).data("name");
    shoppingCart.removeItemFromCart(name);
    shoppingCart.displayCart();
  });

  $("#show-cart").on("click", ".add-item", function(e) {
    e.preventDefault();
    const name = $(this).data("name");
    shoppingCart.addItemToCart(name, 0, 1);
    shoppingCart.displayCart();
  });

  $(".add-to-cart").on("click", function(e) {
    e.preventDefault();
    const name = $(this).data("name");
    const price = Number($(this).data("price"));

    shoppingCart.addItemToCart(name, price, 1);

    shoppingCart.displayCart();
  });
})();
